import { Version } from './version.model';

export const version: Version = {
  build: {
    time: '2024-09-18T11:37:50+0000',
  },
  git: {
    branchName: 'master',
    commitHash: 'b98a8d273a90e72b24ec0ccc486a95b9348f67a6',
  },
};
